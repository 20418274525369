import React, { useState } from 'react';
import { graphql } from 'gatsby';
import 'react-aspect-ratio/aspect-ratio.css';
import styled from 'styled-components';

import Layout from '../layout/main';
import SEOComponent from '../components/seo';
import CTAComponent from '../components/cta';
import ContactForm from '../components/webToLeadForm/ContactForm';
import DropdownSFDC from '../components/inputs/dropdownSFDC';
import GoldenText from '../components/inputs/GoldenText';
import Title from '../components/typography/Title';
import { Wave1 } from '../components/wave';
import WaveImg from './../assets/images/wave21.png';
import reactStringReplace from 'react-string-replace';

import {
    FaEnvelope,
    FaFacebookF,
    FaTwitter,
    FaLinkedinIn,
} from 'react-icons/fa';
const Background = styled.div`
    background-color: transparent;
    background: url(${WaveImg}) no-repeat contain;
    background-position: top 317px;
    margin-bottom: 277px;
    @media screen and (max-width: 767px) {
        margin-bottom: 153px;
    }
`;
const Heading = styled.div`
    margin-left: 176px;
    margin-right: 176px;
    padding-top: 149px;
    user-select: none;
    @media screen and (max-width: 1024px) {
        margin-left: 64px;
        margin-right: 64px;
    }
    @media screen and (max-width: 767px) {
        margin-left: 24px;
        margin-right: 24px;
        padding-top: 108px;
    }
`;
const WaveSection = styled.div`
    position: absolute;
    width: 100%;
    margin-top: -30px;
`;
const Content = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: 176px;
    margin-right: 176px;
    justify-content: space-between;
    margin-top: 210px;
    @media screen and (max-width: 1024px) {
        margin-left: 64px;
        margin-right: 64px;
        flex-direction: row;
    }
    @media screen and (max-width: 767px) {
        margin-left: 24px;
        margin-right: 24px;
        flex-direction: column;
        margin-top: 166px;
    }
`;
const SubTitle = styled.div`
    font-family: 'Renogare-Regular';
    font-size: 36px;
    line-height: 40px;
    letter-spacing: -1.5px;
    text-align: left;
    margin-bottom: 38px;
    color: white;
    user-select: none;
    @media screen and (max-width: 767px) {
        font-size: 24px;
        line-height: 28px;
        letter-spacing: -0.92px;
        margin-bottom: 17px;
    }
`;
const AlertTitle = styled.div`
    font-family: 'Renogare-Regular';
    font-size: 36px;
    line-height: 40px;
    letter-spacing: -1.5px;
    text-align: left;
    margin-top: 38px;
    color: white;
    user-select: none;
    @media screen and (max-width: 767px) {
        font-size: 24px;
        line-height: 28px;
        letter-spacing: -0.92px;
        margin-bottom: 17px;
    }
`;
const AlertMessage = styled.div`
    color: rgba(255, 255, 255, 0.7);
    letter-spacing: normal;
    font-family: 'Neuzeit Grotesk';
    font-size: 17px;
    line-height: 24px;
`;

const Link = styled.a`
    color: #b68f54;
    display: inline-block;
`;
const ContactInfo = styled.div`
    width: 30%;
    margin-top: 0;
    @media screen and (max-width: 767px) {
        width: 100%;
        margin-top: 66px;
    }
`;
const Address = styled.div`
    color: rgba(255, 255, 255, 0.7);
    font-size: 17px;
    line-height: 24px;
    letter-spacing: normal;
    font-family: 'Neuzeit Grotesk';
    margin-top: 12px;
    margin-bottom: 40px;
    user-select: none;
    white-space: pre-wrap;
    @media screen and (max-width: 767px) {
        margin-bottom: 26px;
    }
`;
const ContactDesc = styled.div`
    color: rgba(255, 255, 255, 0.7);
    letter-spacing: normal;
    font-family: 'Neuzeit Grotesk';
    font-size: 17px;
    line-height: 24px;
    margin-top: -10px;
    margin-bottom: 19px;
    user-select: none;
`;
const SocialContainer = styled.div`
    display: flex;
    flex-wrap: wap;
`;
const SocialIcon = styled.a`
    width: 48px;
    height: 48px;
    border-radius: 24px;
    color: white;
    background-color: ${props => props.backColor};
    margin: 8px 16px 8px 0px;
    display: flex;
    justify-content: center;
    line-height: 48px;
    &:first-child {
        margin-left: 0;
    }
    & > * {
        margin-top: auto;
        margin-bottom: auto;
    }
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
    &:active {
        opacity: 0.5;
    }
`;

/**
 * Component for the Contact Us page.
 *
 * @component
 * @param {Object} data - data fetched from the CMS's content-library page
 */
export default function ContactUsPage({ data }) {
    const {
        seo_content,
        hero,
        locations,
        email,
        facebook,
        twitter,
        linkedin,
        send_us_type,
        alert,
    } = data.butterPage;
    const headingTitle = `Reach real people, make real connections,\n
    and drive real brand conversations.`;

    const types = send_us_type.map(item => item.title);
    const [type, setType] = useState(types[0]);

    return (
        <Layout menu="CONTACT US">
            <SEOComponent
                title="Contact Us"
                image={seo_content.sharing_image}
                description={seo_content.seo_description}
            />
            <Background>
                <Heading>
                    <Title>{hero[0].title}</Title>
                    {alert.title && <AlertTitle>{alert.title}</AlertTitle>}
                    {alert.messages.length &&
                        alert.messages.map(({ message }, i) => (
                            <AlertMessage key={`message_${i}`}>
                                {reactStringReplace(
                                    message,
                                    '{LINK}',
                                    (match, i) => (
                                        <Link href={alert.link}>
                                            {alert.link}
                                        </Link>
                                    )
                                )}
                            </AlertMessage>
                        ))}
                </Heading>
                <WaveSection>
                    <Wave1 />
                </WaveSection>
                <Content>
                    <ContactForm
                        formName="Contact Us"
                        subTitle="Send Us a Message"
                        buttonTitle="Send Message"
                        recaptcha={true}
                        type="0"
                        formStyle="width: 60%;
                        @media screen and (max-width: 767px) {
                            width: 100%;
                        }"
                    >
                        <DropdownSFDC
                            id="are_you_a"
                            name="are_you_a"
                            title="Are you a..."
                            list={types}
                            type={type}
                            dropDownName="type"
                            onChooseItem={e => {
                                setType(e);
                            }}
                        />
                    </ContactForm>
                    <ContactInfo>
                        <SubTitle>Office Locations</SubTitle>
                        {locations.map(location => {
                            return (
                                <React.Fragment key={location.region}>
                                    <GoldenText fontSize={14}>
                                        {location.region}
                                    </GoldenText>
                                    <Address>{location.address}</Address>
                                </React.Fragment>
                            );
                        })}
                        <SubTitle>Contact Our Team</SubTitle>
                        <ContactDesc>
                            Get in touch to find out how Audigent can work for
                            you!
                        </ContactDesc>
                        <SocialContainer>
                            <SocialIcon
                                backColor="#A68353"
                                href={`mailto:${email}`}
                            >
                                <FaEnvelope size={24} color="white" />
                            </SocialIcon>
                            <SocialIcon
                                backColor="#4267B2"
                                href={facebook}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FaFacebookF size={24} color="white" />
                            </SocialIcon>
                            <SocialIcon
                                backColor="#55ACEE"
                                href={twitter}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FaTwitter size={24} color="white" />
                            </SocialIcon>
                            <SocialIcon
                                backColor="#2867B2"
                                href={linkedin}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FaLinkedinIn size={24} color="white" />
                            </SocialIcon>
                        </SocialContainer>
                    </ContactInfo>
                </Content>
            </Background>
            <CTAComponent heading={headingTitle} />
        </Layout>
    );
}

export const contactusPageQuery = graphql`
    query {
        butterPage(slug: { eq: "contact-us" }) {
            seo_content {
                seo_description
                sharing_image
            }
            hero {
                title
            }
            locations {
                region
                address
            }
            email
            facebook
            twitter
            linkedin
            send_us_type {
                title
            }
            alert {
                title
                messages {
                    message
                }
                link
            }
        }
    }
`;
